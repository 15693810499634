import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";

const HeroImage = () => {
  const intl = useIntl();

  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return (
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <GatsbyImage
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        image={heroImage.childImageSharp.gatsbyImageData}
        alt={intl.formatMessage({ id: "HERO_IMAGE_ALT" })}
      />
    </div>
  );
};

export default HeroImage;
