import React from "react";
import { Link } from "gatsby";
import useLanguageInformation from "../hooks/useLanguageInformation";

const LanguageSelector = () => {
  const languageInformation = useLanguageInformation();

  const languageLinks = languageInformation.translations.map((translation) => (
    <Link
      key={translation.languageKey}
      className="p-3 hover:bg-gray-300"
      to={translation.url}
    >
      <img
        src={translation.flag}
        alt={translation.i18n.CURRENT_LANGUAGE_NAME}
      />
    </Link>
  ));

  return (
    <div className="ml-3 mr-3 sm:ml-12 sm:mr-12 flex">{languageLinks}</div>
  );
};

export default LanguageSelector;
