import React from "react";
import { useIntl } from "react-intl";
import useLanguageInformation from "../hooks/useLanguageInformation";

import appStoreBadgeDe from "../images/badges/apple-de.png";
import googlePlayBadgeDe from "../images/badges/google-de.png";

import appStoreBadgeEn from "../images/badges/apple-en.png";
import googlePlayBadgeEn from "../images/badges/google-en.png";

const appStoreBadgesMap = new Map<string, any>();
appStoreBadgesMap.set("de", appStoreBadgeDe);
appStoreBadgesMap.set("en", appStoreBadgeEn);

const googlePlayBadgesMap = new Map<string, any>();
googlePlayBadgesMap.set("de", googlePlayBadgeDe);
googlePlayBadgesMap.set("en", googlePlayBadgeEn);

const DownloadBadges = () => {
  const intl = useIntl();
  const languageInformation = useLanguageInformation();

  return (
    <div className="mt-5 sm:mt-8 flex justify-center lg:justify-start">
      <div>
        <a
          href={intl.formatMessage({ id: "STORE_LINK_APPLE" })}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="h-auto sm:h-16 transition ease-in-out duration-300 shadow-xl hover:shadow-2xl origin-center scale-100 hover:scale-105"
            src={appStoreBadgesMap.get(languageInformation.currentLanguageKey)}
            alt={intl.formatMessage({ id: "STORE_BADGE_APPLE_ALT" })}
          />
        </a>
      </div>
      <div className="ml-3">
        <a
          href={intl.formatMessage({ id: "STORE_LINK_GOOGLE" })}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="h-auto sm:h-16 transition ease-in-out duration-300 shadow-xl hover:shadow-2xl origin-center scale-100 hover:scale-105"
            src={googlePlayBadgesMap.get(
              languageInformation.currentLanguageKey
            )}
            alt={intl.formatMessage({ id: "STORE_BADGE_GOOGLE_ALT" })}
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadBadges;
