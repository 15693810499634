import React from "react";
import { FormattedMessage } from "react-intl";
import DownloadBadges from "../DownloadBadges";

const HeroPart = () => {
  return (
    <div className="sm:text-center lg:text-left">
      <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
        <span className="block xl:inline">
          <FormattedMessage id="HERO_TITLE_PART_1" />
        </span>
        <span className="block text-blue-600 xl:inline">
          <FormattedMessage id="HERO_TITLE_PART_2" />
        </span>
      </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        <FormattedMessage id="HERO_BODY" />
      </p>
      <p className="mt-3 text-base text-gray-600 font-bold sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        <FormattedMessage id="HERO_CTA" />
      </p>
      <DownloadBadges />
    </div>
  );
};

export default HeroPart;
